<template>
  <div class="">
    <div class="flex items-center">
      <div class=" w-1/2 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            Objectif de CA mensuel (<span class="text-red">+62%</span>)
          </div>
          <div class="text-c24 font-c4 mt-2">
            10 000 000 <span class="text-90">FCFA</span>
          </div>
        </div>
      </div>

      <div class="w-1/2 ml-8 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            Bon de caisse (<span class="text-green-clear">12 %</span>)
          </div>
          <div class="text-c24 font-c4 mt-2">
            4 540 000 <span class="text-90">FCFA</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'

export default {
  name: "Index",

  data () {
    return {
      icons: {
        arrow
      }
    }
  },
  methods: {
    goto(){
      this.$emit('oga', true)
    }
  },
}
</script>

<style lang="scss" scoped>
  .opn {
    .porte{
      display: none;
    }
  }

  .opn:hover {
    .porte{
      display: flex;
    }
  }
</style>
