<template>
  <div class="p-10 pr-16 all">
    <div
      v-if="activeDetail === null"
      class="flex items-center"
    >
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Points de vente ({{ total }})
        </div>
      </div>
    </div>

    <div
      v-if="activeDetail === null"
      class="mt-6"
    >
      <liste @info="retourListe" @total="retourCount"/>
    </div>

    <div v-if="activeDetail !== null">
      <agence-detail @oga="retourBack" :donnes="activeDetail" />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import liste from '../../component/cdevs/points/liste'
import agenceDetail from '../../component/cdevs/points/agenceDetail'


export default {
  name: "Index",

  components: {
    liste,
    agenceDetail
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter une compagnie',
      activeDetail: null,
      total: 0
      }
  },
  mounted() {
  },
  methods: {

    retourCount (answer) {
      this.total = answer
    },

    retourBack () {
      this.activeDetail = null
    },

    retourListe (answer) {
      this.activeDetail = answer
      console.log(answer)
    },

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 700px;
}
</style>
