<template>
  <collaborateur :activation="activeCollaborateur" v-if="activeCollaborateur && users.length > 0" :donne="agence" :users="users" @oga="retourCollaborateur"/>
  <div class="w-full">
    <div class="flex">
      <div class="w-3/5 rounded-10 h-auto bg-white p-6">
        <div class="text-90 font-c6 text-c16 text-left">
          CHIFFRE D'AFFAIRE MENSUEL
        </div>

        <div class="mt-3">
          <divider
            height="0.8px"
            color="#DDDDDD"
          />
        </div>

        <div class="mt-4">
          <apexchart
            width="590"
            type="bar"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </div>

      <div class="w-2/5 h-full">
        <div class="ml-8 rounded-10 h-full bg-white bloc flex p-6">
          <div class="w-full text-left">
            <div class="flex">
              <div class="text-c20 font-c5 w-1/2">
                Personnel
              </div>

              <div class="w-1/2">
                <bouton
                  height="30.64px"
                  label="Ajouter un collaborateur"
                  size="10,21px"
                  radius="5.67376px"
                  weight="300"
                  class="w-full"
                  @info="activeCollaborateur = true"
                />
              </div>
            </div>

            <div class="mt-12">
              <div
                  v-if="charge"
                  class="flex justify-center mt-6 w-full"
              >
                <easy-spinner
                    type="dots"
                    size="40"
                />
              </div>

              <div class="mt-20 text-center w-3/4 ml-auto mr-auto" v-if="users.length === 0 && !charge">
                Aucune personnel disponible pour ce point de vente
              </div>

              <div class="flex mt-4" v-for="(item, index) in users" :key="index">
                <div class="w-2/3 flex items-center">
                  <namer
                      :first="item.person.fullname"
                      :second="item.person.fullname.substr(1, item.person.fullname.length)"
                      height="35px"
                      width="35px"
                      size="12px"
                  />

                  <div class="ml-4">
                    {{ item.person.fullname }}
                  </div>
                </div>

                <div class="w-1/3 flex justify-end" v-if="agency.responsibles">
                  <bouton
                      v-if="agency.responsibles[0].id === item.person.id"
                    height="30.64px"
                    label="Responsable"
                    background="#EFEFEF"
                    color="#606060"
                    size="13.056px"
                    radius="8.16px"
                    weight="400"
                    class="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import divider from '../../helper/add/divider'
import bouton from '../../helper/add/button'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import namer from '../../helper/add/nameLabel'
import collaborateur from '../../popup/newCollaborator'

export default {
  name: "Index",
  components: {
    divider,
    bouton,
    namer,
    collaborateur
  },

  props: {
    agence: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      series: [{
        name: 'Objectifs',
        data: [12, 55, 57, 56]
      }, {
        name: 'Réalisations',
        data: [76, 85, 141, 98]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '95%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['JAN', 'FEV', 'MAR', 'AVR'],
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1,
          colors: ['#B4E4C9', '#FFD0B1']
        },
        legend: {
          position: 'top',
        }
      },
      users: [],
      charge: true,
      activeCollaborateur: false,
      agency: null
    }
  },

  created() {
    this.allPersonnel()
    this.agency = this.agence
  },

  methods: {
    allPersonnel(){
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.allUser + '?agencyId=' + this.agence.id)
          .then(response => {
            console.log(response)
            this.users = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourCollaborateur (answer) {
      if (answer !== false) {
        this.agency = answer
        this.allPersonnel()
      }
      this.activeCollaborateur = false
    }
  }
}
</script>

<style lang="scss" scoped>
.bloc{
  min-height: 429px;
}
</style>
