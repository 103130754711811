<template>
  <div class="">
    <div v-if="!activePortefeuille">
      <div
        class="flex items-center"
      >
        <div class="w-2/3 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4">
            {{ donnes.label }}
          </div>
        </div>
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <globalStat />
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <turnover :agence="donnes"/>
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../../assets/icons/add.svg'
import back from '../../../assets/icons/backBut.svg'
import globalStat from './globalStat'
import turnover from './turnover'

export default {
  name: "Index",

  components: {
    globalStat,
    turnover
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      activeDetail: false,
      icons: {
        add,
        back
      }
    }
  },

  mounted() {
  },

  methods: {
    retourClick(){
      this.activeDetail =! this.activeDetail
    },

    retour () {
      this.$emit('oga', false)
    }
  }
}
</script>

<style scoped>
.svg-icon {
  fill: transparent;
}
</style>
