<template>
  <div class="w-full flex flex-wrap">
    <div
        v-if="charge"
        class="flex justify-center mt-6 w-full"
    >
      <easy-spinner
          type="dots"
          size="70"
      />
    </div>
    <div
      v-for="(item, index) in allPoints"
      :key="index"
      class="mt-6 w-1/2 pr-2 pl-5"
      @click="selected(item)"
    >
      <div class="bg-white rounded-10 h-auto w-full flex p-6 pb-8 item cursor-pointer">
        <icon
          :data="icons.agence"
          height="70"
          width="70"
          original
        />
        <div class="ml-4 font-c5 text-left">
          <div class="text-c20 mt-2">
            {{ item.label }}
          </div>
          <div class="text-60 font-c3 text-c13">
            {{item.location}}
          </div>

          <div class="text-c12 font-c3 text-60 mt-6">
            Responsable d’agence :
          </div>
          <div class="flex mt-3">
            <namer
                v-if="item.responsibles.length >0"
                :first="item.responsibles[0].fullname"
                :second="item.responsibles[0].fullname.substr(1, item.responsibles[0].fullname.length)"
                height="25px"
                width="25px"
                size="12px"
            />

            <div class="text-c15 ml-3 font-c4" v-if="item.responsibles.length >0">
              {{ item.responsibles[0].fullname }}
            </div>

            <div class="text-c15 ml-3 font-c4" v-if="item.responsibles.length === 0">
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agence from '../../../assets/icons/agenceBlue.svg'
import down from '../../../assets/icons/down.svg'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import namer from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    namer
  },

  data () {
    return {
      icons: {
        arrow,
        agence,
        down
      },
      allPoints: [],
      charge: true
    }
  },

  created () {
    this.allInfo()
  },

  methods: {
    selected (item) {
      this.$emit('info', item)
    },

    allInfo(){
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            console.log(response)
            this.allPoints = response
            this.$emit('total', response.length)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.round{
  background: #9DB7F9;
}
.item:hover {
  box-shadow: -1px -1px 16px 0px #6d9bea52;
}
</style>
